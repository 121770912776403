.additional-questions {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .question-card {
    margin-bottom: 2px;
  
    h6 {
      margin: 0;
    }
  
    .question-body {
      padding: 8px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .main-content {
        flex: 1;
      }
    }
  
    .badge {
      margin-right: 4px;
    }
  }
}