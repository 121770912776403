.image-upload {
  width: 100%;

  .image {
    max-height: 500px;
    object-fit: contain;
  }

  .dropzone-body {
    .dropzone-wrapper {
      height: 150px;
      outline: none;
      padding: 24px;
      text-align: center;
      border: 2px dotted;
      position: relative;

      input {
        outline: none;
      }

      p {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
      }
    }
  }

  .remove-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}