.c-app {
  .c-sidebar {
    .c-sidebar-brand {
      display: flex;
      align-items: center;

      .intors-logo {
        width: 32px;
        height: 32px;
        object-fit: contain;  
        margin-right: 0.5rem;
      }

      h6 {
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .c-sidebar-nav {
    margin-top: 1rem;
  }

  .c-body {
    padding: 1rem;
  }

  .c-header {
    justify-content: flex-end;
  }
}