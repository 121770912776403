.add-event {
  .event-nav {
    max-width: 200px;
  }

  .form-wrapper {
    max-width: 600px;
  }

  .page-head {
    margin-bottom: 24px;
  }

  .action-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  .summary-item {
    padding-top: 8px;
    padding-bottom: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
  }
}