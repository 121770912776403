.login {
  max-width: 360px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  .login-form {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-group {
      align-self: stretch;
    }

    .intors-img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      margin-bottom: 1rem;
    }
  }

  .flexer {
    flex: 1;

    &.top {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}