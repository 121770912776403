.form-field {
  display: flex;
  margin-bottom: 24px;
  margin-top: 0;

  &.dense {
    margin-bottom: 8px;
  }

  &.horizontal {
    flex-direction: column;
    align-items: stretch;
  }

  &.vertical {
    flex-direction: row;
    align-items: center;

    .field-label {
      margin-right: 12px;
    }
  }

  .field-label {
    display: flex;

    .form-text {
      margin-left: 4px;
    }
  }

  input, input:focus {
    color: #000015;
  }
}