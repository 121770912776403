@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

$theme-colors: (
  "primary": #0172BA
);

$font-family-base: 'Open Sans', sans-serif;

@import '~@coreui/coreui/scss/coreui';

html, body, #root {
  height: 100%;
}

.react-toast-notifications__container {
  z-index: 1100 !important;
  top: 72px !important;
}
