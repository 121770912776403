.additional-questions-form {
  .required-toggle {
    display: flex;
  }

  .options-field {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .options-item {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: stretch;

      .form-field {
        flex: 1;
      }

      .options-remove {
        margin-bottom: 8px;
      }
    }
  }
}