.event-detail {
  .page-head {
    margin-bottom: 20px;
  }

  .upload-button {
    width: 200px;
    height: 200px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .upload-button, .event-image {
    margin-bottom: 20px;
  }

  .event-buttons {
    display: flex;

    button {
      margin-right: 12px;
    }
  }
}