.events {
  .jumbotron {
    margin-bottom: 0;
  }

  .bottom-header {
    margin-top: 32px;
  }

  .add-event-button {
    margin-top: 12px;
  }
}